<template>
    <a-modal
     class="address"
      :width="600"
      :visible="updata"
      :title="title"
      cancelText="取消"
      :confirmLoading="loading"
      okText="保存"
      @ok="save"
      :maskClosable="false"
      @cancel="choose === 1 ? ($emit('update:updata',false)) : (choose = 1)"
    >
      <div class="scrollY" v-if="choose === 1">
        <div
          class="ress cursor"
          v-for="item in listAddress"
          :key="item.id"
          :class="item.id == pitchs ? 'bordred' : ''"
          @click="pitch(item.id)"
        >
          <a-row>
            <a-col :span="4" class="color999">地址标签：</a-col>
            <a-col :span="12">{{ item.tagAddr }}</a-col>
          </a-row>
          <a-row>
            <a-col :span="4" class="color999">收货地址：</a-col>
            <a-col :span="15">{{ item.fullAddr }}</a-col>
            <!-- <a-col :span="2" class="cursor">
              <a-popconfirm
                title="Sure to delete?"
                @confirm="() => del(item.id)"
                >删除</a-popconfirm
              ></a-col> -->
            <a-col
              :span="3"
              aligh="right"
              @click="updataRess(item.id)"
              class="cursor site-a"
              >修改地址</a-col
            >
          </a-row>
        </div>
      </div>
      <div
        class="addAdress cursor"
        @click="
          title = '添加收货地址'
          choose = 3
        "
        v-if="choose === 1"
      >
        <a-row type="flex" middle="center">
          <a-col :span="24" align="center"
            ><a-icon type="plus" />&nbsp;&nbsp;<span>新增收货地址</span></a-col
          >
        </a-row>
      </div>
      <!-- 添加地址 -->
      <div v-if="choose === 3 || choose === 2">
        <a-row class="pt10">
          <a-col>地址标签</a-col>
          <a-col class="pt5">
            <a-input
              v-model="addinfo.tagAddr"
              placeholder="给该地址一个别名，便于识别。例如：青岛工厂仓库"
          /></a-col>
        </a-row>
        <a-row class="pt20">
          <a-col>省份/自治区</a-col>
          <a-col class="pt5">
            <a-cascader
              style="width: 100%"
              :field-names="{
                value: 'key',
                label: 'label',
                children: 'children'
              }"
              :key="addinfo.provinceCode"
              :default-value="[addinfo.provinceCode,addinfo.cityCode,addinfo.areaCode]"
              :options="area"
              placeholder="选择省/市/区"
              @change="onChange"
            />
          </a-col>
        </a-row>
        <a-row class="pt20">
          <a-col>详细地址</a-col>
          <a-col class="pt5">
            <a-textarea
              v-model="addinfo.detailAddr"
              :auto-size="{ minRows: 5, maxRows: 5 }"
            />
          </a-col>
        </a-row>
      </div>
    </a-modal>
</template>
<script>
import area from '@/utils/ara'
import { list, createAddress, updateAddress } from '@/api/order'
export default {
    props:['updata','purchaserId'],
  data() {
    return {
      area,
      title: '选择收货地址',
      choose: 1,
      addinfo: {
        provinceCode: '',    //省份编码
        areaCode: '',        //区域编码
        cityCode: '', //城市编码
        detailAddr: '',      //详情地址
        tagAddr: ''          //地址标签
      },
      listAddress: [],
      pitchs: undefined,
      loading: false
    }
  },
  created() {
    this.getlist()  
  },
  methods: {
    getlist() {
       list({
              purchaserId: this.purchaserId
            }).then((res) => {
              this.listAddress = res.data
              if (!res.code === 200) {
                this.$message.error(res.message)
              } else { 
                const showAddress = this.listAddress.find((item) => {
                  return item.id === this.pitchs   
                })
                this.addinfo = {...this.addinfo,...showAddress }
              }
            })
          
    },
    onChange(value) {
      this.addinfo.provinceCode = value[0]
      this.addinfo.cityCode = value[1]
      this.addinfo.areaCode = value[2]
    },
    // 选中
    pitch(id) {
      this.pitchs=id
    },
    // 保存 1选择地址保存 2修改地址保存 3添加地址保存
    save() {
      if (!this.verify()) return
      this.loading = true
      if (this.choose === 2) {
        //   修改地址保存
        updateAddress({...this.addinfo,addrId:this.pitchs }).then((res) => {
          this.loading = false
          if (res.code === 200) {
            this.choose=1
             this.$message.success(res.message)
             this.getlist()
          } else {
            this.$message.error(res.message)
          }
        }).catch(r=>{
            console.log(r);
          this.loading = false
            })
      } else if (this.choose === 3) {
        //  添加地址保存
        createAddress({ ...this.addinfo }).then((res) => {
          this.loading = false
          if (res.code === 200) {
            this.getlist()
            this.choose=1
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        }).catch(r=>{
            console.log(r);
          this.loading = false
        })
      } else {
        this.loading = false
      }
    },
    updataRess(id) {
      this.title = '修改收货地址'
      this.choose = 2
      this.pitchs = id
      this.getlist()
    },
    // 删除地址
    // del(id) {
    //   deleteAddress({ addrId: id }).then((res) => {
    //     if (res.code === 200) {
    //       this.$message.success(res.message)
    //       this.getlist()
    //     } else {
    //       this.$message.error(res.message)
    //     }
    //   })
    // },
    // 验证
    verify() {
      if (!this.addinfo.tagAddr) {
        this.$message.error('请填写一个地址标签')
      } else if (!this.addinfo.provinceCode) {
        this.$message.error('请选择一个省市区')
      } else if (!this.addinfo.detailAddr) {
        this.$message.error('请填写一个详细地址')
      } else return true
    }
  }
}
</script>
<style lang="less" scoped>

    .ress {
  height: 110px;
  border: 1px solid #ccc;
  padding: 30px;
  margin-top: 20px;
  transition: all 0.2s;
}
.scrollY {
  height: 410px;
  overflow-y: auto;
}
.addAdress {
  margin-top: 20px;
  padding: 30px;
  font-size: 16px;
  border: 1px solid #ccc;
}
.cursor {
  cursor: pointer;
}
.site-a {
  color: @primary;
}
.bordred {
  border: 1px solid red;
}
/deep/.ant-modal-title {
  text-align: center;
}
.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt20 {
  padding-top: 20px;
}

</style>