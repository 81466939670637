<template>
  <div class="payment">
    <div class="paybody">
      <PayMoney :bord="true" title="订单总金额" :total="total" />
      <div class="arrows">
        <img src="../../../assets/images/u203.svg" alt="" />
      </div>
      <div class="pay-count">
        <PayMoney title="货款金额" :total="net" />
        <PayMoney title="税款金额" :total="tax" />
      </div>
    </div>
    <div class="order-count">
      <a-row type="flex" justify="end" style="padding:0 0 20px 0 ">
        <!-- <a-col :span="3" align="right"><a-button type="primary" @click="pay" v-if="authRoleId!==2 && !(total.progress === '100%')">新增支付</a-button></a-col> -->
        <!-- <a-col :span="2"><a-button type="primary">下载回单</a-button></a-col> -->
      </a-row>
      <a-table 
        rowKey="id"
        :columns="columns"
        size="middle"
        :data-source="list"
        :pagination="{
          size: 'middle',
          total: getsellerlist.total,
          current: getsellerlist.pageNum,
          pageSize: getsellerlist.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '15'],
          showTotal: (total) => `共有 ${getsellerlist.total} 条数据`,
          showQuickJumper: true,
          onShowSizeChange: (current, pageSize) => {
            getsellerlist.pageSize = pageSize
            getsellerlist.pageNum = 1
            this.getlist()
          },
          onChange: (pageNum) => {
            getsellerlist.pageNum = pageNum
            this.getlist()
          },
        }"
            >
        <!-- <template slot="id" slot-scope="text">
          <a class="id-primary">{{ text }}</a>
        </template> -->
        <template slot="state" slot-scope="text,record">
          <span v-if="record.state=='success'"><a-badge status="success" /></span>
          <span v-else-if="record.state==''"><a-badge status="error" /></span>
          <span v-else-if="record.state=='1'"> <a-badge status="default" /></span>
          <span v-else-if="record.state=='2'">  <a-badge status="processing" /></span>
          <span>{{text}}</span>
        </template>
        <template slot="netAmount" slot-scope="text">
          <span>{{text.toFixed(2)}}</span>
        </template>
        <template slot="taxAmount" slot-scope="text">
          <span>{{text.toFixed(2)}}</span>
        </template>
        <template slot="action" slot-scope="text,record">
          <a-space>
            <a-popconfirm
              v-if="record.closeFlag==1"
              cancelText="取消"
              okText="确定"
              title="确定关闭?"
              @confirm="() => closePay(record.id)">
              <a>关闭支付</a>
            </a-popconfirm>
             <span v-if="record.bankReceiptFile" @click="lookReceipt(record.bankReceiptFile)"><a>查看回单</a> </span>
          </a-space>
        </template>
      </a-table> 
    </div>
     <!-- 支付 -->
        <a-modal
            title="支付"
            :visible="visible"
            :footer="null"
            :width="990"
            @cancel="cancel"
          >
            
            <PayMent :id="$route.query.entityId" :authRoleId="authRoleId" :orderId="$route.query.id" @getlist="getlist()"/>
          </a-modal>
            
          <a-modal :width="800" :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
           <img style="width: 100%" :src="bigImg" />
          </a-modal>
  </div>
</template>
<script>
import PayMoney from "./components/pay.vue";
import { mapGetters } from 'vuex'
import{channel } from "@/utils/constArr"
import {list,closePay,progress}  from '@/api/pay'
import PayMent from '../components/payment.vue' 

export default {
  components: {
    PayMent,
    PayMoney
  },
  data() {
    return {
      columns:[
        {title: "支付ID",dataIndex: "id",scopedSlots: { customRender: "id" },},
        {title: "创建时间",align:'left',dataIndex: "createdTime",},
        {title: "支付编号",align:'left',dataIndex: "paymentNo",},
        {title: "收款方账号",align:'left',dataIndex: "receiptAccountNo",},
        {title: "订单关联货款",align:'right',dataIndex: "netAmount",scopedSlots: { customRender: "netAmount" },},
        {title: "关联税费",align:'right',dataIndex: "taxAmount",scopedSlots: { customRender: "taxAmount" },},
        {title: "状态",dataIndex: "payStatus",align:'left',scopedSlots: { customRender: "payStatus" },},
        {title: "支付渠道",dataIndex: "channelType",},
        {title: "到账时间",dataIndex: "settleTime",},
        {title: "操作",dataIndex: "action",scopedSlots: { customRender: "action" },},
        ],
        list:[],
        getsellerlist: {
        pageNum: 1,
        pageSize: 5,
        totalPage: 1,
        total: 1
      },
      total:{},
      net:{},
      tax:{},
      visible: false,
      authRoleId: undefined, //权限
      payAll: {
        bankAccountList: [],
        fee: undefined, //总费用
        netFee: undefined, //贷款
        taxFee: undefined //税款
      },
      previewVisible:false,
      bigImg:'',
    };
  },
  filters:{
        channel:(val)=>channel[val]
  },
  created(){
    this.getlist()
    this.getProgress()
  },
  computed:{
    ...mapGetters(['getRoleP']),
  },
  methods:{
    //获取列表数据
    getlist(){
        this.authRoleId = this.getRoleP.find(
            (item) => item.entityId == this.$route.query.entityId).authRoleId
        this.authRoleId == 2 && this.columns.pop()
      list({...this.getsellerlist,orderId: this.$route.query.id}).then(res=>{
        this.getsellerlist.pageNum = res.data.pageNum
        this.getsellerlist.pageSize = res.data.pageSize
        this.getsellerlist.totalPage = res.data.totalPage
        this.getsellerlist.total = res.data.total
        this.list=res.data.list   
      })   
    },
    //获取统计表
    getProgress(){
      progress({orderId:this.$route.query.id}).then(res=>{
        this.total=res.data.total
        this.net=res.data.net
        this.tax=res.data.tax
      })
    },
    onSearch(e) {
      this.searchText = e
      this.getlist()
    },
    onChange(date, dateString) {
      this.beginTime = dateString[0]
      this.endTime = dateString[1]
      this.getlist()
    },
    cancel() {
      this.visible = false
    },

    // 点击支付
    pay() {
      this.visible = true
    },

    //查看回单
    lookReceipt(img){
    this.previewVisible = true
    this.bigImg=img
    },
    // 关闭支付
    closePay(id){
      closePay(id).then(res=>{
        if (res.code===200) {
          this.$message.success(res.message)
          this.getlist()
        }else{
          this.$message.error(res.message)
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.payment {
  padding: 20px;
  background-color: #FFFFFF;
  .paybody {
    @media screen and (max-width: 1440px){
        transform: scale(.85);
    }
    display: flex;
    justify-content: center;
    .arrows {
      padding: 60px 0 0 10px;
    }
    .pay-count {
      display: flex;
      justify-content: space-evenly;
      width: 747px;
      height: 196px;
      background-color: rgba(242, 242, 242, 1);
      border-radius: 5px;
      padding: 20px 20px;
    }
  }
  .order-count {
    padding: 50px 0 0 0;
  }
}
// 支付样式
.left-c {
  line-height: 32px;
  text-align: right;
}
.left-b {
  line-height: 10px;
  text-align: left;
}
.logo {
  width: 143px;
  height: 55px;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  img {
    margin-top: 5px;
    width: 100%;
  }
  .pt5 {
    padding: 5px 0 0 0;
  }
}
.zs {
  height: 250px;
}
.pt20 {
  padding: 0 0 0 20px;
}
/deep/ .ant-col {
  margin-bottom: 20px;
}

/deep/.ant-input-number:hover{
  border-color: red;
}
/deep/.ant-input-number-focused{
  border-color: red;
  box-shadow: none;
}

</style>