var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment"},[_c('div',{staticClass:"paybody"},[_c('PayMoney',{attrs:{"bord":true,"title":"订单总金额","total":_vm.total}}),_vm._m(0),_c('div',{staticClass:"pay-count"},[_c('PayMoney',{attrs:{"title":"货款金额","total":_vm.net}}),_c('PayMoney',{attrs:{"title":"税款金额","total":_vm.tax}})],1)],1),_c('div',{staticClass:"order-count"},[_c('a-row',{staticStyle:{"padding":"0 0 20px 0"},attrs:{"type":"flex","justify":"end"}}),_c('a-table',{attrs:{"rowKey":"id","columns":_vm.columns,"size":"middle","data-source":_vm.list,"pagination":{
        size: 'middle',
        total: _vm.getsellerlist.total,
        current: _vm.getsellerlist.pageNum,
        pageSize: _vm.getsellerlist.pageSize,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '15'],
        showTotal: function (total) { return ("共有 " + (_vm.getsellerlist.total) + " 条数据"); },
        showQuickJumper: true,
        onShowSizeChange: function (current, pageSize) {
          _vm.getsellerlist.pageSize = pageSize
          _vm.getsellerlist.pageNum = 1
          this$1.getlist()
        },
        onChange: function (pageNum) {
          _vm.getsellerlist.pageNum = pageNum
          this$1.getlist()
        },
      }},scopedSlots:_vm._u([{key:"state",fn:function(text,record){return [(record.state=='success')?_c('span',[_c('a-badge',{attrs:{"status":"success"}})],1):(record.state=='')?_c('span',[_c('a-badge',{attrs:{"status":"error"}})],1):(record.state=='1')?_c('span',[_c('a-badge',{attrs:{"status":"default"}})],1):(record.state=='2')?_c('span',[_c('a-badge',{attrs:{"status":"processing"}})],1):_vm._e(),_c('span',[_vm._v(_vm._s(text))])]}},{key:"netAmount",fn:function(text){return [_c('span',[_vm._v(_vm._s(text.toFixed(2)))])]}},{key:"taxAmount",fn:function(text){return [_c('span',[_vm._v(_vm._s(text.toFixed(2)))])]}},{key:"action",fn:function(text,record){return [_c('a-space',[(record.closeFlag==1)?_c('a-popconfirm',{attrs:{"cancelText":"取消","okText":"确定","title":"确定关闭?"},on:{"confirm":function () { return _vm.closePay(record.id); }}},[_c('a',[_vm._v("关闭支付")])]):_vm._e(),(record.bankReceiptFile)?_c('span',{on:{"click":function($event){return _vm.lookReceipt(record.bankReceiptFile)}}},[_c('a',[_vm._v("查看回单")])]):_vm._e()],1)]}}])})],1),_c('a-modal',{attrs:{"title":"支付","visible":_vm.visible,"footer":null,"width":990},on:{"cancel":_vm.cancel}},[_c('PayMent',{attrs:{"id":_vm.$route.query.entityId,"authRoleId":_vm.authRoleId,"orderId":_vm.$route.query.id},on:{"getlist":function($event){return _vm.getlist()}}})],1),_c('a-modal',{attrs:{"width":800,"visible":_vm.previewVisible,"footer":null},on:{"cancel":function($event){_vm.previewVisible = false}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.bigImg}})])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"arrows"},[_c('img',{attrs:{"src":require("../../../assets/images/u203.svg"),"alt":""}})])}]

export { render, staticRenderFns }