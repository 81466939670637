<template>
  <div class="order">
    <div class="mainbody">
      <a-row  type="flex" justify="space-between" align="middle" style="background-color:#fff;margin: auto;">
        <p class="mainbody-title">订单：{{ $route.query.id }}</p>
        <p class="start">
          <!-- <span>
            <a-rate v-model="value" :tooltips="desc" />
            <span class="ant-rate-text">{{ desc[value - 1] }}</span>
          </span> -->
        </p>
      </a-row>
      <a-tabs :default-active-key="$route.query.p?$route.query.p:'1'" style="margin-bottom: 20px">
        <a-tab-pane key="1" tab="信息流">
          <OrderDetails/>
        </a-tab-pane>
        <a-tab-pane key="2" tab="合同流">
          <Contract/>
        </a-tab-pane>
        <a-tab-pane key="3" tab="资金流">
          <Payment />
        </a-tab-pane>
        <a-tab-pane key="4" tab="货流">
          <Logistics />
        </a-tab-pane>
        <a-tab-pane key="5" tab="票流">
          <Invoice />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import OrderDetails from "./OrderDetails.vue";
import Payment from "./Payment.vue";
import Logistics from "./logistics.vue";
import Invoice from "./Invoice.vue";
import Contract from "./contract.vue"
export default {
  components: {
    OrderDetails,
    Payment,
    Logistics,
    Invoice,
    Contract
  },
  data() {
    return {
      orderNum: 1728,    
      // value: 3,
      // desc: ["1星", "2星", "3星", "4星", "5星"],
    };
  },
};
</script>

<style lang="less" scoped>
.order {
  max-width: 1440px;
  min-width: 990px;
  margin: auto;
   @media screen and (max-width: 1440px){
    padding: 0 15px; 
  }
  box-sizing: border-box;
  overflow: hidden;
  .mainbody {
    border-top: 1px solid rgb(232, 232, 232);
    margin: auto;
    margin-bottom: 100px;
    .mainbody-title {
      padding: 20px;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.847058823529412);
      font-weight: 700;
    }
    .start{
      padding: 20px;
    }
    /deep/ .ant-tabs-nav-scroll {
      padding: 0 20px;
      background: #fff;
    }
  }
}
</style>