<template>
  <div class="order">
    <a-card title="流程进度" class="card">
      <a-steps class="pt20">
        <a-step title="创建合同" status="finish" :description="info.createdTime" />
        <a-step title="合同签署" :status="info.contractPro === '100%' && 'finish'" :description="info.contractPro"/>
        <a-step title="支付" :status="info.paymentPro === '100%' && 'finish'" :description="info.paymentPro" />
        <a-step title="物流上传" :status="info.logisticsPro === '100%' && 'finish'" :description="info.logisticsPro" />
        <a-step title="完成" :status="(info.logisticsPro === '100%' && info.contractPro === '100%' && info.paymentPro === '100%') && 'process'" />
      </a-steps>
    </a-card>

    <a-card title="订单内容" class="card">
      <a-table
        :data-source="data"
        :columns="orderColumns"
        :pagination="false"
        :rowKey="(record,index)=>{return index}"
        :loading="loading">
        <template slot="price" slot-scope="text">
            <span class="red">{{text}}</span>
        </template>
        <template slot="footer">
          <div class="footer">
            <b>总价</b>
            <b class="red">{{typeof info.contractAmount=='number'&& info.contractAmount.toFixed(2) }}</b>
          </div>
        </template>
          <template slot="unitPrice" slot-scope="text">
          <span v-if="text">{{ text.toFixed(2) }}</span>
        </template>
      </a-table>
    </a-card>

    <a-card class="card" title="卖家信息">
      <a-row>
        <a-col :span="8">名称：<router-link :to="`/shop/${'s'+info.supplierId}`">{{ info.supplierName }}</router-link></a-col>
        <a-col :span="8">卖家性质：{{ info.supplierType }}</a-col>
        <a-col :span="8">税号：{{ info.supplierUnifiedCode }}</a-col>
        <a-col :span="8">姓名：<a>{{ info.supplierLegalName }}</a></a-col>
        <a-col :span="8">联系电话：{{ info.supplierMobile }}</a-col>
        <a-col :span="8">身份证号：{{ info.supplierIdCard }}</a-col>
      </a-row>
    </a-card>

    <a-card class="card" title="买家信息">
      <a-row>
        <a-col :span="8">名称：<a>{{ info.purchaserCommercialName }}</a></a-col>
        <a-col :span="8">税号：{{ info.purchaserUniformCode }}</a-col>
        <a-col :span="8">
          <a-space :size="20">
            收货地址：{{ info.delyFullAddr }}  
          <span><a-icon @click="updata=true" type="form" v-if="false"/></span>
          </a-space>
        </a-col>
        <a-col v-if="info.purchaserDownstreamName" :span="8">对应下游：{{ info.purchaserDownstreamName }}</a-col>
        <a-col v-if="info.downstreamUniformCode" :span="8">税号：{{ info.downstreamUniformCode }}</a-col>
      </a-row>
    </a-card>

    <Address v-if="updata" :updata.sync="updata" :purchaserId="info.purchaserId"/>
    
  </div>
</template>

<script>
import { infoflow } from '@/api/order'
import Address from './components/address.vue'
const orderColumns = [
  {
    title: '商品名称',
    dataIndex: 'mdseName'
  },
  {
    title: '发票名称',
    dataIndex: 'invoiceName',
    align: 'left'
  },
  {
    title: '规格',
    dataIndex: 'specName',
    align: 'right'
  },
  {
    title: '单价',
    dataIndex: 'unitPrice',
    scopedSlots: { customRender: 'unitPrice' },
    align: 'right'
  },
  {
    title: '数量',
    dataIndex: 'quantity',
    align: 'right'
  },
  {
    title: '金额',
    dataIndex: 'price',
    scopedSlots: { customRender: 'price' },
    align: 'right'
  }
]
export default {
  components:{
    Address,
  },
  data () {
      return {
          updata:false,
          data: [],
          orderColumns,
          loading:false,
          info: {},
      }
  },
  computed: {
    current () {
      if (this.info.contractPro !== '100%') {
        return 1
      } else {
        return 5
      }
    }
  },
  mounted () {
    infoflow(this.$route.query.id).then(res => {
      if (res.code === 200) {
        this.info = res.data
        this.info.logisticsPro = this.percent(this.info.logisticsPro)
        this.info.contractPro = this.percent(this.info.contractPro)
        this.info.paymentPro = this.percent(this.info.paymentPro)
        this.data.push({ ...this.info, price: this.info.taxableAmount.toFixed(2) })
        this.data.push({ mdseName: '增值税', quantity: this.info.vatRate*100+'%', price: this.info.vatTaxAmount.toFixed(2) })
      } 
    })
  },
  methods: {
    percent(val) {
      const num = Number(val.split('%')[0])
      return (num >100 ? 100 : num) + '%'
    }
  }
}
</script>

<style lang="less" scoped>

.card {
    margin-bottom: 20px;
}
.contract-title {
    font-weight: 700;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 20px;
    line-height: 28px;
}
.footer {
  display: flex;
  justify-content: space-between;
}
.pt20{
  padding: 20px;
}
.red{
  color: red;
  font-size: 16px;
}

/deep/ .ant-card-bordered {
    border: none;
    position: relative;
}
/deep/ .ant-col {
    margin-bottom: 20px;
}

</style>
